import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from "gatsby"
import {Container, Row, Col} from '../../../components/ui/wrapper'
import Heading from '../../../components/ui/heading'
import {PageHeaderWrap} from './page-header.style'

const TeamPageHeader = ({sectionStyle, titleStyle, descStyle}) => {
    const TeamPageHeaderData = useStaticQuery(graphql `
        query TeamPageHeaderQuery {
            aboutUs(id: {eq: "team-page-header-data"}) {
                title
                desc
                bg_image
              }
        } 
    `);
    const {title, desc, bg_image} = TeamPageHeaderData.aboutUs
    return (
        <PageHeaderWrap src={bg_image}>
            <Container>
                <Row textalign="center">
                    <Col lg={8} ml="auto" mr="auto">
                        {title && <Heading {...titleStyle}>{title}</Heading>}
                        {desc && <Heading {...descStyle}>{desc}</Heading>}
                    </Col>
                </Row>
            </Container>
        </PageHeaderWrap>
    )
}

TeamPageHeader.propTypes = {
    descStyle: PropTypes.object,
    titleStyle: PropTypes.object
}

TeamPageHeader.defaultProps = {
    titleStyle: {
        as: 'h1',
        color: "#fff",
        mb: '15px'
    },
    descStyle: {
        as: "h5",
        color: "#fff",
        fontweight: 400
    }
}

export default TeamPageHeader;