import React from 'react'
import SEO from "../components/seo"
import Layout from '../containers/layout/layout'
import Header from '../containers/layout/header/header-one'
import Footer from '../containers/layout/footer/footer-one'
import PageHeader from '../containers/team/page-header'
import CTA from '../containers/global/cta-area/section-one'
import SectionOne from '../containers/elements/team-member/section-one'
import CircleSectionOne from '../containers/elements/progress-circle/section-one'
import SectionTwo from '../containers/elements/progress-bar/section-two'

const OurTeamPage = ({ pageContext, location }) => {
    return (
        <Layout location={location}>
            <SEO title="Team Member" />
            <Header />
            <PageHeader/>
            <main className="site-wrapper-reveal">
                <SectionOne />
                <CircleSectionOne/>
                <SectionTwo />
                <CTA />
            </main>
            <Footer />
        </Layout>
    )
}

export default OurTeamPage
