import React from 'react'
import { Container, Row, Col } from '../../../../components/ui/wrapper'
// import ProgressBarThree from '../../../../components/ui/progress-bar/layout-three'
import { useStaticQuery, graphql } from "gatsby"
import ProgressBarFour from '../../../../components/ui/progress-bar/layout-four'
import { SectionWrap, ProgressTwoWrap } from './style'
import SectionTitle from '../../../../components/ui/section-title'

const ProgresBarSection = () => {
    const TeamPageQueryData = useStaticQuery(graphql`
    query TeamSizeDataQuery{
        skills(id: {eq: "team-size"}) {
            skillLeft {
              title
              value
            }
            skillRight {
              title
              value
            }
            title
            desc
          }
    }
`);

const TeamSkillsLeft = TeamPageQueryData.skills.skillLeft;
const TeamSkillsRight = TeamPageQueryData.skills.skillRight;
const {title, desc} = TeamPageQueryData.skills;

console.log('ga', TeamSkillsLeft)

    return (
        <SectionWrap>
            <Container>
            <Row>
                    <Col lg={12}>
                        <SectionTitle
                            title={title}
                        />
                    </Col>
                </Row><br></br>
                <p style={{textAlign: "center"}}>{desc}</p>
                <br></br>
                <Row>
                    <Col lg={6}>
                    <ProgressTwoWrap>
                            {TeamSkillsLeft && TeamSkillsLeft.map((skill, i) => (
                                <ProgressBarFour
                                    key={`prog-one-${i}`}
                                    title={skill.title}
                                    value={skill.value}
                                    gradient={i + 1}
                                />
                            ))}
                        </ProgressTwoWrap>
                    </Col>
                    <Col lg={6}>
                        <ProgressTwoWrap>
                            {TeamSkillsRight && TeamSkillsRight.map((skill, i) => (
                                <ProgressBarFour
                                    key={`prog-two-${i}`}
                                    title={skill.title}
                                    value={skill.value}
                                    gradient={i + 1}
                                />
                            ))}
                        </ProgressTwoWrap>
                    </Col>
                </Row>
            </Container>
        </SectionWrap>
    )
}

export default ProgresBarSection

